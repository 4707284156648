import { Controller } from '@hotwired/stimulus'
import { Dropdown } from 'bootstrap'

export default class extends Controller {

  connect() {
    const toggle = this.element.querySelector('.dropdown-toggle')
    const menu = this.element.querySelector('.dropdown-menu')
    this.dropdown = new Dropdown(toggle)
    toggle.addEventListener('mouseover', this.onMouseover.bind(this))
    toggle.addEventListener('mouseleave', this.onMouseleave.bind(this))
    menu.addEventListener('mouseover', this.onMouseover.bind(this))
    menu.addEventListener('mouseleave', this.onMouseleave.bind(this))
  }

  onMouseover(event) {
    setTimeout(() => {
      if (this.dropdown._menu.contains(event.target) || this.dropdown._element.contains(event.target)) {
        this.dropdown.show()
      }
    }, 250)
  }

  onMouseleave(event) {
    setTimeout(() => {
      if (!this.isHovering()) {
        this.dropdown.hide()
      }
    }, 250)
  }

  isHovering() {
    return this.dropdown._menu.matches(':hover') || this.dropdown._element.matches(':hover')
  }

  disconnect() {
    const toggle = this.element.querySelector('.dropdown-toggle')
    const menu = this.element.querySelector('.dropdown-menu')
    toggle.removeEventListener('mouseover', event => this.onMouseover(event))
    toggle.removeEventListener('mouseleave', event => this.onMouseleave(event))
    menu.removeEventListener('mouseover', event => this.onMouseover(event))
    menu.removeEventListener('mouseleave', event => this.onMouseleave(event))
  }
}
